/* Set font-size on html element to make sizes for @material-ui work properly.
as it falls back to html element to calculate sizes for 'rem' */
html {
  font-size: 14px;
}

.main { padding-top: 50px; }
.sketch-picker{
  position: absolute;
  z-index: 3;
}
img {
  max-width: 100%;
  max-height: 100%;
}
.img-thumbnail {
  max-width: 150px;
  max-height: 150px;
}
.page-header .btn.pull-right { margin-left: 10px; }
